import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'gatsby';
import styles from './CookieConsentBar.module.scss';
import type { Location } from '../../types';

type Props = {
  location: Location,
};

const oneWeek = new Date().getTime() + 24 * 7 * 3600 * 1000;

const CookieConsentBar = ({
  location,
}: Props) => {
  const currentPath = location?.pathname || '';
  const privacyPageRegex = /pages\/privacy/;
  const isPrivacyPolicyPage = privacyPageRegex.test(currentPath);

  return (
    <CookieConsent
      location="bottom"
      sameSite="lax"
      expires={oneWeek}
      acceptOnScroll={!isPrivacyPolicyPage}
      style={{
        backgroundColor: '#222',
      }}
      buttonStyle={{
        backgroundColor: '#fcd722',
        color: '#222',
        fontSize: '16px',
      }}
    >
      This blog uses cookies to improve user experience. By using the site you accept these cookies. Read our <Link className={styles['cookieConsentBar-link']} to={'/pages/privacy'}>privacy policy</Link>.
    </CookieConsent>
  );
};

export default CookieConsentBar;
